import React, { useEffect, useState, createContext } from "react";

export const AppContext = createContext({});

export function AppProvider({ children }) {
  const [activeFeatures, setActiveFeatures] = useState();
  const [menuOpen, setMenuOpen] = useState(false);
  const [buildingData, setBuildingData] = useState();
  const [contentScreen, setContentScreen] = useState(-1);

  /**
   * Track content screens in GA
   */
  useEffect(() => {
    const screens = [
      "About",
      "Usage",
      "Download Data",
      "Contact Us",
      "Main Map"
    ];
    const screenIndex =
      contentScreen === -1 ? screens.length - 1 : contentScreen;
    window.gtag("event", "screen_view", {
      app_name: "Development Activity Model",
      screen_name: screens[screenIndex]
    });
  }, [contentScreen]);

  useEffect(() => {}, [menuOpen]);

  return (
    <AppContext.Provider
      value={{
        activeFeatures,
        setActiveFeatures,
        menuOpen,
        setMenuOpen,
        buildingData,
        setBuildingData,
        contentScreen,
        setContentScreen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
