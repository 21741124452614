import {
  APPLIED,
  APPROVED,
  UNDER_CONSTRUCTION,
  CONSTRUCTED,
  BUILDING_COLORS,
  COMPLETED_DATA
} from "../dam/constants";
import { theme } from "../common/theme";

export const damExtrusionColor = [
  "match",
  ["get", "status"],
  [APPLIED],
  BUILDING_COLORS.main[APPLIED],
  [APPROVED],
  BUILDING_COLORS.main[APPROVED],
  [UNDER_CONSTRUCTION],
  BUILDING_COLORS.main[UNDER_CONSTRUCTION],
  BUILDING_COLORS.main[CONSTRUCTED]
];
export const accessibleDamExtrusionColor = [
  "match",
  ["get", "status"],
  [APPLIED],
  BUILDING_COLORS.a11y[APPLIED],
  [APPROVED],
  BUILDING_COLORS.a11y[APPROVED],
  [UNDER_CONSTRUCTION],
  BUILDING_COLORS.a11y[UNDER_CONSTRUCTION],
  BUILDING_COLORS.a11y[CONSTRUCTED]
];

export const damLayer = {
  id: "dam",
  type: "fill-extrusion",
  source: "dam",
  paint: {
    "fill-extrusion-height": ["get", "extrusion"],
    "fill-extrusion-color": [...damExtrusionColor]
  }
};

export const highlightLayer = {
  id: "highlight",
  type: "fill-extrusion",
  source: "highlight",
  paint: {
    "fill-extrusion-height": ["+", ["get", "extrusion"], 1],
    "fill-extrusion-color": theme.palette.common.white,
    "fill-extrusion-opacity": 0.5
  }
};

export const selectionLayer = {
  id: "selection",
  type: "fill-extrusion",
  source: "selection",
  paint: {
    "fill-extrusion-height": ["+", ["get", "extrusion"], 2],
    "fill-extrusion-color": theme.palette.primary.main,
    "fill-extrusion-opacity": 1
  }
};

export const completedLayer = {
  id: "completed",
  type: "fill-extrusion",
  source: {
    type: "geojson",
    data: COMPLETED_DATA
  },
  paint: {
    "fill-extrusion-height": ["get", "extrusion"],
    "fill-extrusion-color": theme.palette.grey200,
    "fill-extrusion-opacity": 0.5
  }
};
