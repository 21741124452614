import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

import { AppProvider } from "./dam/AppContext";
import WelcomeDialog from "./dam/WelcomeDialog";

import { MapProvider } from "./maps/MapContext";

import { createGlobalStyle } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { theme } from "./common/theme";
import { MapboxProvider } from "./maps/MapboxContext";
import { A11yProvider } from "./dam/A11yContext";

// import FPSStats from "react-stats-zavatta";
import { DEFAULT_VIEW_STATE } from "./dam/constants";

const App = React.lazy(() => import("./dam/App"));

const GlobalStyle = createGlobalStyle`
  html, body { 
    margin: 0;
    overflow: 'hidden'
  }
  * { box-sizing: border-box }
`;

ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function Root() {
  const [welcomeDialogOpen, setWelcomeDialogOpen] = React.useState(
    !window.location.search.includes("hideWelcomeDialog")
  );
  return (
    <StyledThemeProvider theme={theme}>
      <MuiThemeProvider theme={theme}>
        {/* <FPSStats isActive={true} /> */}
        <GlobalStyle />
        <WelcomeDialog
          open={welcomeDialogOpen}
          onClose={() => setWelcomeDialogOpen(false)}
        />
        <MapProvider initialViewState={DEFAULT_VIEW_STATE}>
          <A11yProvider>
            <AppProvider>
              <MapboxProvider>
                <React.Suspense fallback={""}>
                  <App />
                </React.Suspense>
              </MapboxProvider>
            </AppProvider>
          </A11yProvider>
        </MapProvider>
      </MuiThemeProvider>
    </StyledThemeProvider>
  );
}
