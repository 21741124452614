import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import "./fonts/fonts.css";

const fontFamily = [
  "Gotham",
  "Roboto",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"'
].join(",");

const headingFontFamily = `"CoM", ${fontFamily}`;

/**
 * Primary colour palette is dark background.
 * Secondary is white background.
 */

export const theme = createMuiTheme({
  palette: {
    primary: { main: "#e50e56" },
    secondary: { main: "#277bb4" },
    error: { main: "#F07B05" },
    pop: {
      yellow: "#FFC726",
      green: "#008850",
      purple: "#645FAA",
      blue: "#00AECB"
    },
    a11y: {
      yellow: "#FDE724",
      green: "#32B679",
      purple: "#440D54"
    },
    black: "#000000",
    grey800: "#080809",
    grey600: "#23242b",
    grey400: "#3c404b",
    grey200: "#d4d6db",
    grey100: "#f2f3f4",
    white: "#fff"
  },
  typography: {
    useNextVariants: true,
    // Use the system font instead of the default Roboto font.
    fontFamily,
    h1: { fontFamily: headingFontFamily },
    h2: { fontFamily: headingFontFamily },
    h3: { fontFamily: headingFontFamily },
    h4: { fontFamily: headingFontFamily },
    h5: { fontFamily: headingFontFamily },
    h6: { fontFamily: headingFontFamily, fontSize: 30 }
  },
  overrides: {
    MuiListItemText: {
      primary: {
        // fontWeight: "bold"
      }
    },
    MuiTab: {
      wrapper: {
        // color: "#fff"
      }
    }
  }
});
