import React from "react";
import Dialog from "@material-ui/core/Dialog";

import {
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
  Box
} from "@material-ui/core";

export default React.memo(function WelcomeDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Welcome</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Welcome to City of Melbourne's Development Activity Model (DAM). This
          provides a representation of major development activity in the
          Melbourne Local Government Area. The visualisation is underpinned by
          information from the City of Melbourne Planning systems.
        </DialogContentText>

        <DialogContentText>
          All 3D Models are simplified interpretations of complex built forms
          and should not be used for planning purposes.
        </DialogContentText>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="primary">
            Got it!
          </Button>
        </DialogActions>
        {/* survey was here */}
        
      </DialogContent>
    </Dialog>
  );
}, ({open:prev}, {open:next}) => prev === next)
