import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useRef
} from "react";
import { AppContext } from "../dam/AppContext";
import {
  damExtrusionColor,
  accessibleDamExtrusionColor,
  damLayer,
  highlightLayer,
  selectionLayer,
  completedLayer
} from "./mapboxLayers";
import { featureCollection } from "@turf/helpers";
import buffer from "@turf/buffer";

import { theme } from "../common/theme";
import { A11yContext } from "../dam/A11yContext";
import {
  APPLIED,
  APPROVED,
  UNDER_CONSTRUCTION,
  DEVELOPMENT_DATA
} from "../dam/constants";

export const MapboxContext = createContext();

export function MapboxProvider(props) {
  /**
   * Load data for DAM on mount (no deps in useEffect)
   */
  const [data, setData] = useState();
  useEffect(() => {
    fetch(DEVELOPMENT_DATA)
      .then(res => res.json())
      .then(res => setData(res));
  }, []);

  /**
   * Get mapRef
   * TODO: Potentially refactor this ref to be within this context.
   * Since we're not using deck.gl does it make sense to have it there?
   */
  const mapRef = useRef();
  const map = mapRef.current && mapRef.current;

  window.mapRef = map;

  /**
   * Manage highlights
   */
  const [highlightedId, setHighlightedId] = useState();
  useEffect(() => {
    // if there's no map or source, return to avoid TypeError
    if (!map || !map.getSource("highlight")) return;

    // if there's no highlightedId (or undefined) empty the
    // highlight feature collection
    if (!highlightedId) {
      map.getSource("highlight").setData(featureCollection([]));
      return;
    }

    // if there is, get all polygons that are under the same
    // permit id
    const features = data.features.filter(
      d => d.properties.permit_id === highlightedId
    );

    // set dem polygons as the source of the highlight.
    // this layer sits above it and 'wraps' the underlying
    // polygon. You can make some cool glassy effects by
    // playing with the buffer size.
    if (features.length) {
      map
        .getSource("highlight")
        .setData(featureCollection(features.map(d => buffer(d, 0.0001))));
    }
  }, [highlightedId, map, data]);

  /**
   * Manage selection. This works exactly the same as managing
   * highlights, with one small difference - the buffer is
   * larger to allow the selection (pink) to cover the highlight
   * (transparent.)
   */
  const [selectedId, setSelectedId] = useState();
  const { setActiveFeatures } = useContext(AppContext);
  useEffect(() => {
    if (!map || !map.getSource("selection")) return;
    if (!selectedId) {
      map.getSource("selection").setData(featureCollection([]));
      return;
    }

    const features = data.features.filter(
      d => d.properties.permit_id === selectedId
    );

    if (features.length) {
      map
        .getSource("selection")
        .setData(featureCollection(features.map(d => buffer(d, 0.0015))));
    }
  }, [selectedId, map, data, setActiveFeatures]);

  /**
   * Manage filtering
   */
  const [filters, setFilters] = useState([
    {
      key: "status",
      condition: "in",
      value: [APPLIED, APPROVED, UNDER_CONSTRUCTION]
    }
  ]);

  useEffect(() => {
    // avoid a TypeError
    if (!map || !map.getLayer("dam")) return;
    // create base next state
    const nextFilters = ["all"];

    // loop through filters
    for (let n = 0; n < filters.length; n++) {
      const filter = filters[n];

      // status filter needs to 'match'. This is similar
      // to an 'in' or 'includes' function (which doesn't
      // exist yet)
      if (filter.key === "status") {
        nextFilters.push([
          "match",
          ["get", "status"],
          filter.value,
          true,
          false
        ]);
      } else {
        // for commercial or residential.
        nextFilters.push(["==", ["get", filter.key], true]);
      }
    }

    // set the filters on the map instance.
    map.setFilter("dam", nextFilters);
  }, [filters, map]);

  /**
   * Add layers to the map
   */
  const onMapboxLoad = () => {
    /**
     * DAM Layer
     */
    map.addSource("dam", {
      type: "geojson",
      data
    });
    map.addLayer(damLayer, "place-suburb");

    /**
     * Highlight layer
     */
    map.addSource("highlight", {
      type: "geojson",
      data: featureCollection([])
    });

    map.addLayer(highlightLayer, "place-suburb");

    /**
     * Selection layer
     */
    map.addSource("selection", {
      type: "geojson",
      data: featureCollection([])
    });

    map.addLayer(selectionLayer, "place-suburb");

    /**
     * Completed layer
     */
    map.addLayer(completedLayer, "place-suburb");
  };

  /**
   * Accessibility mode
   */
  const { a11yMode } = useContext(A11yContext);
  useEffect(() => {
    if (!map || !map.getLayer("dam") || !map.getLayer("selection")) return;
    if (a11yMode) {
      map.setPaintProperty(
        "selection",
        "fill-extrusion-color",
        theme.palette.grey800
      );
      map.setPaintProperty(
        "dam",
        "fill-extrusion-color",
        accessibleDamExtrusionColor
      );
    } else {
      map.setPaintProperty(
        "selection",
        "fill-extrusion-color",
        theme.palette.primary.main
      );
      map.setPaintProperty("dam", "fill-extrusion-color", damExtrusionColor);
    }
  }, [a11yMode, map]);

  return (
    <MapboxContext.Provider
      value={{
        filters,
        setFilters,
        selectedId,
        setSelectedId,
        highlightedId,
        setHighlightedId,
        onMapboxLoad,
        mapRef,
        data
      }}
      {...props}
    />
  );
}
