import React, { useRef, useState } from "react";
import { createContext } from "react";
import useInterval from "@rooks/use-interval";
import { FlyToInterpolator } from "react-map-gl";
import centroid from "@turf/centroid";

export const MapContext = createContext({});

export function MapProvider({
  children,
  initialViewState = DEFAULT_VIEW_STATE
}) {
  /**
   * Control viewState of the map
   */
  const [viewState, setViewState] = useState({
    ...initialViewState
  });

  /**
   * Fly to coordinates
   */

  const flyTo = vs =>
    setViewState(
      vs({
        ...viewState,
        transitionDuration: 350,
        transitionInterpolator: new FlyToInterpolator()
      })
    );

  /**
   * Fly to a specific object
   */
  const flyToPolygon = polygon => {
    const { geometry } = centroid(polygon);
    const [longitude, latitude] = geometry.coordinates;
    flyTo(vs => ({
      ...vs,
      latitude,
      longitude
    }));
  };

  /**
   * Used for a shared GL context
   */
  const mapRef = useRef();
  const deckRef = useRef();

  /**
   * Handle rotation
   */
  const totalRotation = 4 * 60 * 1000;
  const rotate90Deg = () =>
    setViewState(vs => ({
      ...vs,
      bearing: vs.bearing + 90,
      transitionDuration: totalRotation / 4
    }));
  const { start, stop } = useInterval(rotate90Deg, totalRotation / 4 + 1);

  /**
   * Immediately start rotating and kick off transition
   */
  const startRotating = () => {
    rotate90Deg();
    start();
  };

  /**
   * Stop the interval and break transition
   */
  const stopRotating = () => {
    stop();
    setViewState(vs => ({ ...vs, latitude: vs.latitude + 0.00001 }));
  };

  /**
   * Gets the next calculated view state, then
   * applies limitations to it so we don't go
   * out of bounds.
   *
   * @todo add function that wraps setBounds
   * and checks for correct bounds format
   */
  const [bounds, setBounds] = useState();
  const setViewStateInBounds = vsFn => {
    setViewState(vs => {
      /**
       * Can pass either a function or an object. You'd
       * pass a function to get access to the previous
       * viewState.
       */
      const nextViewState = typeof vsFn === "function" ? vsFn(vs) : vsFn;
      if (bounds) {
        let { latitude, longitude, ...rest } = nextViewState;
        const [
          [minLongitude, minLatitude],
          [maxLongitude, maxLatitude]
        ] = bounds;

        if (latitude < minLatitude) {
          latitude = minLatitude;
        } else if (latitude > maxLatitude) {
          latitude = maxLatitude;
        }

        if (longitude < minLongitude) {
          longitude = minLongitude;
        } else if (longitude > maxLongitude) {
          longitude = maxLongitude;
        }

        return {
          ...vs,
          ...rest,
          latitude,
          longitude
        };
      } else {
        return nextViewState;
      }
    });
  };

  const [markers, setMarkers] = useState([]);

  return (
    <MapContext.Provider
      value={{
        viewState,
        setViewState,
        setViewStateInBounds,
        flyTo,
        flyToPolygon,
        mapRef,
        deckRef,
        startRotating,
        stopRotating,
        markers,
        setMarkers,
        bounds,
        setBounds
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

const DEFAULT_VIEW_STATE = {
  latitude: -37.8136,
  longitude: 144.96332,
  zoom: 14,
  pitch: 40,
  bearing: 0,
  transitionEasing: t => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t)
};
