import { theme } from "../common/theme";

export const APPLIED = "Applied";
export const APPROVED = "Approved";
export const UNDER_CONSTRUCTION = "Under Construction";
export const CONSTRUCTED = "Constructed";

export const COMPLETED_DATA =
  process.env.REACT_APP_DATA_STAGE === "production"
    ? "https://dam-datasets.s3-ap-southeast-2.amazonaws.com/Footprints_Completed_Production.json"
    : "https://dam-datasets.s3-ap-southeast-2.amazonaws.com/Footprints_Completed_Test.json";
export const DEVELOPMENT_DATA =
  process.env.REACT_APP_DATA_STAGE === "production"
    ? "https://dam-datasets.s3-ap-southeast-2.amazonaws.com/Footprints_Developments_Production.json"
    : "https://dam-datasets.s3-ap-southeast-2.amazonaws.com/Footprints_Developments_Test.json";

export const BUILDING_COLORS = {
  main: {
    [APPLIED]: theme.palette.pop.blue,
    [APPROVED]: theme.palette.pop.green,
    [UNDER_CONSTRUCTION]: theme.palette.pop.yellow,
    [CONSTRUCTED]: theme.palette.grey200
  },
  a11y: {
    [APPLIED]: theme.palette.a11y.purple,
    [APPROVED]: theme.palette.a11y.green,
    [UNDER_CONSTRUCTION]: theme.palette.a11y.yellow,
    [CONSTRUCTED]: theme.palette.grey200
  }
};

export const DEFAULT_VIEW_STATE = {
  latitude: -37.8136,
  longitude: 144.96332,
  zoom: 14.5,
  pitch: 40,
  bearing: 0
};
export const DEFAULT_VIEW_STATE_XS = {
  latitude: -37.818,
  longitude: 144.96,
  zoom: 13.5,
  pitch: 40,
  bearing: 30
};
